<!-- eslint-disable @angular-eslint/template/cyclomatic-complexity -->
<ng-container *ngIf="itemTraits() as traits">
    <div class="newrow left-aligned tags" *ngIf="traits.length">
        <app-trait *ngFor="let trait of traits; trackBy:trackers.trackByIndex;" [name]="trait"
            [trait]="traitFromName(trait)">
        </app-trait>
    </div>
</ng-container>
<div class="newrow left-aligned tags">
    <app-tags [creature]="creature" [objectName]="item.name" [showTraits]=true [showFeats]=true [showItems]=true
        [showActivities]=true [showConditions]=true [showEffects]=true></app-tags>
</div>
<ng-container *ngIf="itemRoles() as itemRoles">
    <div class="newrow"
        *ngIf="itemRoles.asEquipment && itemRoles.asEquipment.choices?.length && (itemStore ? true : itemRoles.asEquipment.showChoicesInInventory)">
        <span>Variation:
            <select [(ngModel)]="itemRoles.asEquipment.choice" (ngModelChange)="onSelectVariation()">
                <option *ngFor="let choice of itemRoles.asEquipment.choices; trackBy:trackers.trackByIndex;"
                    [ngValue]="choice">
                    {{choice}}
                </option>
            </select>
        </span>
    </div>
    <!-- Doubling Rings selection -->
    <ng-container *ngIf="itemRoles.asWornItem as asWornItem">
        <div class="newrow"
            *ngIf="!itemStore && asWornItem.isDoublingRings && !asWornItem.isTalismanCord && !asWornItem.isRingOfWizardry.length">
            <header class="spellHeader">Doubling Rings</header>
            <div class="newrow">
                <strong>Weapon wielded in the gold ring hand</strong>
                <select [(ngModel)]="item.data[0].value" (ngModelChange)="onSelectDoublingRingsOption(asWornItem)">
                    <option *ngFor="let weapon of doublingRingsOptions('gold'); trackBy:trackers.trackByIndex;"
                        [ngValue]="weapon.id">
                        {{weapon.effectiveName()}}
                    </option>
                </select>
            </div>
            <div class="newrow">
                <strong>Weapon wielded in the iron ring hand</strong>
                <select [(ngModel)]="item.data[1].value" (ngModelChange)="onSelectDoublingRingsOption(asWornItem)">
                    <option *ngFor="let weapon of doublingRingsOptions('iron'); trackBy:trackers.trackByIndex;"
                        [ngValue]="weapon.id">
                        {{weapon.effectiveName()}}
                    </option>
                </select>
            </div>
            <div class="newrow left-aligned" *ngIf="asWornItem.isDoublingRings === 'Doubling Rings (Greater)'">
                <input id="{{item.id}}doublingRingsApplyPropertyRunes" type="checkbox" [(ngModel)]="item.data[2].value"
                    (ngModelChange)="onSelectDoublingRingsOption(asWornItem)">
                <label for="{{item.id}}doublingRingsApplyPropertyRunes">Apply property runes</label>
            </div>
        </div>
        <!-- Ring of Wizardry selection -->
        <div class="newrow"
            *ngIf="!itemStore && asWornItem.isRingOfWizardry.length && !asWornItem.isTalismanCord && !asWornItem.isDoublingRings">
            <header class="spellHeader">Ring of Wizardry</header>
            <div class="newrow"
                *ngFor="let wizardrySlot of asWornItem.isRingOfWizardry; let wizardryIndex = index; trackBy:trackers.trackByIndex">
                <strong>{{ringOfWizardrySlotName(wizardrySlot)}}</strong>
                <select [(ngModel)]="item.data[wizardryIndex].value"
                    (ngModelChange)="onSelectRingOfWizardryOption(asWornItem, wizardrySlot, wizardryIndex)">
                    <option
                        *ngFor="let spellCasting of ringOfWizardryOptions(wizardrySlot); trackBy:trackers.trackByIndex;"
                        [ngValue]="spellCasting">
                        {{spellCasting}}
                    </option>
                </select>
            </div>
        </div>
        <!-- Talisman Cord school display/selection -->
        <div class="newrow"
            *ngIf="asWornItem.isTalismanCord && !asWornItem.isDoublingRings && !(asWornItem.isRingOfWizardry.length)">
            <header class="spellHeader">Talisman Cord</header>
            <ng-container *ngFor="let talismanCordIndex of [0, 1, 2]; trackBy:trackers.trackByIndex;">
                <div class="newrow"
                    *ngIf="asWornItem.isTalismanCord > talismanCordIndex && item.data[talismanCordIndex]">
                    <strong>{{item.data[talismanCordIndex].name}}</strong>
                    <select [(ngModel)]="item.data[talismanCordIndex].value">
                        <option
                            *ngFor="let school of talismanCordOptions(asWornItem, talismanCordIndex); trackBy:trackers.trackByIndex;"
                            [ngValue]="school">
                            {{school}}
                        </option>
                    </select>
                </div>
            </ng-container>
        </div>
    </ng-container>
    <app-itemContent [item]="item"></app-itemContent>
    <!-- Item activities -->
    <div class="list-item left-aligned"
        *ngFor="let activity of itemRoles.asActivityBearing?.activities; trackBy:trackers.trackByIndex;">
        <header class="spellHeader" *ngIf="activity.name">
            {{activity.name}}
            <app-actionIcons *ngIf="activity.actions" [actionString]="activity.actions">
            </app-actionIcons>
            {{(activity.activationType) ? activity.activationType : ""}}
        </header>
        <app-activity [creature]="creature" [activity]=activity [gain]=activity
            [allowActivate]="allowActivate && !(itemRoles.asEquipment?.broken) && !!(activity.resonant ? isSubItem : true)"
            [isSubItem]="isSubItem">
        </app-activity>
    </div>
    <!-- Known activities -->
    <ng-container *ngFor="let gain of itemRoles.asEquipment?.gainActivities || []; trackBy:trackers.trackByIndex;">
        <div class="list-item left-aligned" *ngIf="activityFromName(gain.name) as activity">
            <header class="spellHeader">
                {{activity.name}}
                <app-actionIcons *ngIf="activity.actions" [actionString]="activity.actions">
                </app-actionIcons>
                {{(activity.activationType) ? activity.activationType : ""}}
            </header>
            <app-activity [creature]="creature" [activity]=activity [gain]=gain [allowActivate]="allowActivate"
                [isSubItem]="isSubItem">
            </app-activity>
        </div>
    </ng-container>
    <!-- Shown/related activities (Talismans only) -->
    <ng-container
        *ngFor="let activityName of itemRoles.asTalisman?.showActivities || []; trackBy:trackers.trackByIndex;">
        <div class="list-item left-aligned" *ngIf="activityFromName(activityName) as activity">
            <header class="spellHeader">
                {{activity.name}}
                <app-actionIcons *ngIf="activity.actions" [actionString]="activity.actions">
                </app-actionIcons>
                {{(activity.activationType) ? activity.activationType : ""}}
            </header>
            <app-activity [creature]="creature" [activity]=activity [allowActivate]=false [isSubItem]="isSubItem">
            </app-activity>
        </div>
    </ng-container>
    <!-- Spells that get cast by activating the item (Oils only) -->
    <div class="list-item left-aligned"
        *ngFor="let cast of itemRoles.asOil?.castSpells || []; trackBy:trackers.trackByIndex;">
        <div *ngIf="spellFromName(cast.name) as spell">
            <header class="spellHeader">{{spell.name}}
                <app-actionIcons *ngIf="spell.actions" [actionString]="spell.actions">
                </app-actionIcons>
                {{spell.castType.toString()}}
            </header>
            <app-spell [spell]=spell [spellLevel]="cast.level ? cast.level : 0"></app-spell>
        </div>
    </div>
    <!-- Spells that you gain from equipping/investing the item -->
    <ng-container *ngFor="let choice of itemRoles.asEquipment?.gainSpells || []; trackBy:trackers.trackByIndex;">
        <div class="list-item left-aligned" *ngFor="let gain of choice.spells; trackBy:trackers.trackByIndex;">
            <div *ngIf="spellFromName(gain.name) as spell">
                <header class="spellHeader">{{spell.name}}
                    <app-actionIcons *ngIf="spell.actions" [actionString]="spell.actions">
                    </app-actionIcons>
                    {{spell.castType.toString()}}
                </header>
                <app-spell [spell]=spell [spellLevel]="gainedSpellLevel(spell, {gain, choice})">
                </app-spell>
            </div>
        </div>
    </ng-container>
    <!-- Material description -->
    <div class="list-item left-aligned"
        *ngFor="let material of itemRoles.asEquipment?.material || []; trackBy:trackers.trackByIndex;">
        <header class="spellHeader">{{material.name}}</header>
        <div class="newrow left-aligned" *ngIf="material.sourceBook">
            <strong>Source</strong>
            <i>{{material.sourceBook}}</i>
        </div>
        <ng-container *ngFor="let desc of material.desc.split('\n\n'); trackBy:trackers.trackByIndex;">
            <app-description class="newrow" [text]="desc"></app-description>
        </ng-container>
    </div>
    <!-- Armored skirt description if one is applied -->
    <div class="list-item left-aligned" *ngIf="armoredSkirt">
        <header class="spellHeader">Armored Skirt</header>
        <app-item [item]=armoredSkirt></app-item>
    </div>
    <!-- Spell choices for wands/scrolls/etc -->
    <div class="list-item left-aligned" *ngFor="let choice of storedSpellChoices(item); trackBy:trackers.trackByIndex;">
        <app-spellchoice class="newrow" [choice]="choice" [showHeightened]="true" [level]="choice.level"
            [itemSpell]="true">
        </app-spellchoice>
    </div>
    <!-- Spell descriptions and effect selections of selected spells -->
    <ng-container *ngFor="let spellSet of storedSpellsTaken(item); trackBy:trackers.trackByIndex;">
        <div class="list-item left-aligned" *ngIf="spellFromName(spellSet.taken.name) as spell">
            <header class="spellHeader">
                {{spell.name}}
                <app-actionIcons *ngIf="spell.actions" [actionString]="spell.actions"></app-actionIcons>
                {{spell.castType.toString()}}
            </header>
            <ng-container *ngIf="!itemStore">
                <ng-container
                    *ngFor="let conditionSet of spellConditions(spell, spellSet.choice.level, spellSet.taken); let conditionSetIndex = index; trackBy:trackers.trackByIndex">
                    <div class="newrow list-item left-aligned"
                        *ngIf="conditionSet.condition.$choices.length && !conditionSet.gain.choiceBySubType && !conditionSet.gain.choiceLocked && !conditionSet.gain.copyChoiceFrom && !conditionSet.gain.hideChoices">
                        <span>
                            {{conditionSet.condition.name}} effect selection:
                            <select [(ngModel)]="spellSet.taken.effectChoices[conditionSetIndex].choice">
                                <option
                                    *ngFor="let choice of conditionSet.condition.$choices; trackBy:trackers.trackByIndex;"
                                    [ngValue]="choice">
                                    {{choice}}
                                </option>
                            </select>
                        </span>
                    </div>
                </ng-container>
            </ng-container>
            <app-spell [spell]=spell [spellLevel]="spellSet.choice.level || 0">
            </app-spell>
        </div>
    </ng-container>
    <!-- Oil descriptions -->
    <div class="list-item left-aligned" *ngFor="let oil of item.oilsApplied; trackBy:trackers.trackByIndex;">
        <header class="spellHeader">{{oil.effectiveName()}}</header>
        <app-item [creature]="creature" [item]=oil [allowActivate]=allowActivate [isSubItem]=true></app-item>
    </div>
    <!-- Poison descriptions -->
    <div class="list-item left-aligned"
        *ngFor="let poison of itemRoles.asWeapon?.poisonsApplied || []; trackBy:trackers.trackByIndex;">
        <header class="spellHeader">{{poison.name}}</header>
        <button class="newrow left-aligned" *ngIf="allowActivate && itemRoles.asWeapon"
            (click)="onActivatePoison(itemRoles.asWeapon, poison)">
            <span>Spend/Remove</span>
        </button>
        <app-item [creature]="creature" [item]=poison [allowActivate]=allowActivate [isSubItem]=true></app-item>
    </div>
    <!-- Rune descriptions -->
    <div class="list-item left-aligned"
        *ngFor="let rune of itemRoles.asEquipment?.propertyRunes || []; trackBy:trackers.trackByIndex;">
        <header class="spellHeader">{{rune.name}} Rune</header>
        <ng-container *ngIf="runeStoredSpell(rune) as spell">
            <button class="newrow center-aligned" (click)="onActivateSpellRune(rune)">
                <span>
                    Use
                    <app-actionIcons *ngIf="spell.actions" [actionString]="spell.actions">
                    </app-actionIcons>
                    (Cast spell)
                </span>
            </button>
        </ng-container>
        <app-item [creature]="creature" [item]=rune [allowActivate]=allowActivate [isSubItem]=true></app-item>
    </div>
    <!-- Blade ally rune descriptions -->
    <ng-container *ngIf="(itemRoles.asWeapon || itemRoles.asWornItem) as bladeAllyItem">
        <ng-container *ngIf="bladeAllyItem.bladeAlly">
            <div class="list-item left-aligned"
                *ngFor="let rune of bladeAllyItem.bladeAllyRunes; trackBy:trackers.trackByIndex;">
                <header class="spellHeader">{{rune.name}} Rune</header>
                <app-item [creature]="creature" [item]="rune" [allowActivate]="allowActivate" [isSubItem]="true">
                </app-item>
            </div>
        </ng-container>
    </ng-container>
    <!-- Emulated rune descriptions (oils) -->
    <div class="list-item left-aligned" *ngIf="!!itemRoles.asOil && !!itemRoles.asOil.runeEffect">
        <header class="spellHeader">Rune Effect: {{itemRoles.asOil.runeEffect.name}}</header>
        <app-item [creature]="creature" [item]="itemRoles.asOil.runeEffect" [allowActivate]=allowActivate
            [isSubItem]=isSubItem>
        </app-item>
    </div>
    <!-- Aeon stone descriptions -->
    <div class="list-item left-aligned"
        *ngFor="let stone of itemRoles.asWornItem?.aeonStones || []; trackBy:trackers.trackByIndex;">
        <header class="spellHeader">{{stone.name}}</header>
        <app-item [creature]="creature" [item]=stone [allowActivate]=allowActivate [isSubItem]=true></app-item>
    </div>
    <!-- Talisman cord descriptions -->
    <div class="list-item left-aligned lower"
        *ngFor="let cord of itemRoles.asEquipment?.talismanCords || []; trackBy:trackers.trackByIndex;">
        <header class="spellHeader">{{cord.name}}</header>
        <p>
            When you activate a talisman threaded through a cord with the same magic school trait that's also the cord's
            level or lower, attempt a <app-quickdice [diceString]="'1d20'"></app-quickdice> DC 16 flat check. On a
            success,
            that talisman is not consumed and can be used again.
        </p>
        <ng-container *ngFor="let talismanCordIndex of [0, 1, 2]; trackBy:trackers.trackByIndex;">
            <div class="newrow" *ngIf="cord.isTalismanCord > talismanCordIndex && cord.data[talismanCordIndex]">
                <strong>{{cord.data[talismanCordIndex].name}}</strong>
                {{cord.data[talismanCordIndex].value}}
            </div>
        </ng-container>
    </div>
    <!-- Talisman descriptions and activation -->
    <div class="list-item left-aligned"
        *ngFor="let talisman of itemRoles.asEquipment?.talismans || []; let index = index; trackBy:trackers.trackByIndex;">
        <header class="spellHeader">{{talisman.name}}</header>
        <button class="newrow left-aligned" (click)="onActivateTalisman(itemRoles, talisman, index)"
            *ngIf="allowActivate">
            <span>
                Activate
                <app-actionIcons *ngIf="talisman.actions" [actionString]="talisman.actions">
                </app-actionIcons>
                {{(talisman.activationType) ? talisman.activationType : ""}}
            </span>
        </button>
        <button class="newrow left-aligned" (click)="onActivateTalisman(itemRoles, talisman, index, {preserve: true})"
            *ngIf="allowActivate && itemRoles.asEquipment && hasMatchingTalismanCord(itemRoles.asEquipment, talisman)">
            <span>
                Activate and preserve with talisman cord
                <app-actionIcons *ngIf="talisman.actions" [actionString]="talisman.actions">
                </app-actionIcons>
                {{(talisman.activationType) ? talisman.activationType : ""}}
            </span>
        </button>
        <app-item [creature]="creature" [item]=talisman [allowActivate]=allowActivate [isSubItem]=true></app-item>
    </div>
</ng-container>
